import React from "react";

export default function CustomSelect({
  value,
  handleChange,
  id,
  label,
  name,
  options,
}) {
  return (
    <div
      className={`custom_calc-select md:min-h-[72px] flex md:block border border-[#E2E5EC] rounded-md w-full p-1.5 text-start`}
    >
      <span className="p-1 hidden md:inline">{label}</span>
      <select
        value={value}
        onChange={handleChange}
        className="block mt-2 w-[100%] cursor-pointer text-sm m-1.5"
        id={id}
        label={label}
        name={name}
      >
        <option disabled={true} value="">
          {label}
        </option>
        {options.map((item, index) => {
          return (
            <option
              value={id === "symbol" ? item.symbol : item.value}
              key={index}
            >
              {id === "symbol" ? item.symbol : item.title}
            </option>
          );
        })}
      </select>
    </div>
  );
}
