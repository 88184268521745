import axios from "axios";

export const getliveRates = async () => {
  try {
    const res = await axios.get(
      "https://mk.quotes.gomarkets.com/goau/mtquotes/goaumtquotes.php"
    );
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.error("Error fetching live rates:", error);
  }
};
