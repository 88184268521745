import { getliveRates } from "../services/liveRatesActions";

export const LivePrices = async (formValue) => {
  const priceData = await getliveRates();

  if (!priceData) {
    console.error("No price data available");
    return { ask: 0, bid: 0, spread: 0 }; // Handle the case when there's no data
  }

  let liveRates = priceData[formValue.assetClass];
  let ask = 0;
  let bid = 0;
  let spread = 0;

  if (liveRates) {
    if (liveRates[formValue.symbol]) {
      ask = liveRates[formValue.symbol][2];
      bid = liveRates[formValue.symbol][3];
      spread = ask - bid;
    }
  }

  return { ask, bid, spread };
};
