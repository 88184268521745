import { Instruments } from "./instruments";

export const CalculateCommission = (lots, symbol, accountType) => {
  var filterInstrument = Instruments().filter(
    (value) => value.symbol === symbol
  );

  return accountType === "plus"
    ? lots * filterInstrument[0]?.commission * 2
    : 0;
};
