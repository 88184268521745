export const Tiers = () => {
  return [
    {
      asset_class: "Forex",
      tier: 1,
      lower_band: 10000000.0,
      upper_band: 49999999.0,
      rebate_percentage: 10,
    },
    {
      asset_class: "Forex",
      tier: 2,
      lower_band: 50000000.0,
      upper_band: 99999999.0,
      rebate_percentage: 15,
    },
    {
      asset_class: "Forex",
      tier: 3,
      lower_band: 100000000.0,
      upper_band: 249999999.0,
      rebate_percentage: 25,
    },
    {
      asset_class: "Forex",
      tier: 4,
      lower_band: 250000000.0,
      upper_band: null,
      rebate_percentage: 30,
    },
    {
      asset_class: "Indices",
      tier: 1,
      lower_band: 10000000.0,
      upper_band: 49999999.0,
      rebate_percentage: 5,
    },
    {
      asset_class: "Indices",
      tier: 2,
      lower_band: 50000000.0,
      upper_band: 99999999.0,
      rebate_percentage: 10,
    },
    {
      asset_class: "Indices",
      tier: 3,
      lower_band: 100000000.0,
      upper_band: 249999999.0,
      rebate_percentage: 15,
    },
    {
      asset_class: "Indices",
      tier: 4,
      lower_band: 250000000.0,
      upper_band: null,
      rebate_percentage: 20,
    },
    {
      asset_class: "Commodities",
      tier: 1,
      lower_band: 5000000.0,
      upper_band: 24999999.0,
      rebate_percentage: 4,
    },
    {
      asset_class: "Commodities",
      tier: 2,
      lower_band: 25000000.0,
      upper_band: 49999999.0,
      rebate_percentage: 8,
    },
    {
      asset_class: "Commodities",
      tier: 3,
      lower_band: 50000000.0,
      upper_band: 99999999.0,
      rebate_percentage: 12,
    },
    {
      asset_class: "Commodities",
      tier: 4,
      lower_band: 100000000.0,
      upper_band: null,
      rebate_percentage: 20,
    },
  ];
};
