import { Tiers } from "./tiers";

export const CalculateTier = (volume, assetClass) => {
  var filterTiersClass = Tiers().filter(
    (value) => value.asset_class.toUpperCase() === assetClass
  );

  var filterTier = filterTiersClass.filter((value) => {
    let tier = null;
    if (
      volume >= value.lower_band &&
      (volume <= value.upper_band || value.upper_band === null)
    ) {
      tier = value.tier;
    }

    return tier;
  });

  return filterTier[0]?.tier;
};
