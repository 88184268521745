import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// Wait for DOM content to load
document.addEventListener("DOMContentLoaded", function () {
  const rootElement = document.getElementById("react-root");

  // Only render the app if the root element exists (i.e., the shortcode is used)
  if (rootElement) {
    const root = ReactDOM.createRoot(rootElement);
    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>
    );
  }
});

// Measuring performance (optional)
reportWebVitals();
