import React, { createContext, useContext, useEffect } from "react";
import { CalculateRebatePer } from "../helper/calculateRebatePer";
import { LivePrices } from "../helper/livePrices";
import { useHandleChange } from "../hooks/handleChange";
import { CalculateLots } from "../helper/calculateLots";
import { CalculateCommission } from "../helper/calculateCommission";
import { CalculateSpread } from "../helper/calculateSpread";
import { CalculateTier } from "../helper/calculateTier";

const AppContext = createContext();

export const useAppContext = () => {
  return useContext(AppContext);
};

export const AppProvider = ({ children }) => {
  const { formValue, setFormValue, handleFieldChange } = useHandleChange({
    spread: 0,
    accountType: "plus",
    assetClass: "",
    symbol: "",
    volume: 0,
    lots: 0,
    rebateTier: null,
    rebateAmount: 0,
    commission: 0,
  });

  useEffect(() => {
    const fetchAndUpdateValues = async () => {
      let updatedValues = { ...formValue };

      setInterval(async () => {
        if (formValue.symbol) {
          const prices = await LivePrices(formValue);
          updatedValues.spread = prices.spread;
        }
      }, 300000);

      const rebateAmount = CalculateRebatePer(
        formValue.assetClass,
        formValue.rebateTier
      );
      if (updatedValues.rebateAmount !== rebateAmount) {
        updatedValues.rebateAmount = rebateAmount;
      }

      // Only calculate lots if volume or symbol changes
      const lots = CalculateLots(formValue.volume, formValue.symbol);
      if (updatedValues.lots !== lots && formValue.volume) {
        updatedValues.lots = lots;
      }

      const commission = CalculateCommission(
        formValue.lots,
        formValue.symbol,
        formValue.accountType
      );
      if (updatedValues.commission !== commission) {
        updatedValues.commission = commission;
      }

      const spread = CalculateSpread(formValue.symbol, formValue.volume);
      if (formValue.symbol && formValue.volume) {
        if (updatedValues.spread !== spread) {
          updatedValues.spread = spread;
        }
      }

      const rebateTier = CalculateTier(formValue.volume, formValue.assetClass);
      if (formValue.assetClass && formValue.volume) {
        if (updatedValues.rebateTier !== rebateTier) {
          updatedValues.rebateTier = rebateTier;
        }
      }

      setFormValue(updatedValues);
    };

    fetchAndUpdateValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formValue.symbol,
    formValue.assetClass,
    formValue.rebateTier,
    formValue.volume,
    formValue.lots,
    formValue.accountType,
  ]);

  return (
    <AppContext.Provider value={{ formValue, handleFieldChange }}>
      {children}
    </AppContext.Provider>
  );
};
