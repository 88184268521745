import { Tiers } from "./tiers";

export const CalculateRebatePer = (assetClass, rebateTier) => {
  var filterTier = Tiers().filter(
    (value) =>
      value.tier === rebateTier &&
      value.asset_class.toUpperCase() === assetClass
  );

  return filterTier[0]?.rebate_percentage || 0;
};
