import "./App.scss";
import Calculator from "./calculator";
import { AppProvider } from "./context/appContext";

function App() {
  return (
    <AppProvider>
      <div className="flex w-full justify-center">
        <Calculator />
      </div>
    </AppProvider>
  );
}

export default App;
