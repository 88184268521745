import { Instruments } from "./instruments";

export const CalculateLots = (volume, symbol) => {
  if (symbol) {
    var filterInstrument = Instruments().filter(
      (value) => value.symbol === symbol
    );
  }

  return volume && filterInstrument ? volume / filterInstrument[0]?.lotSize : 0;
};
