import React from "react";

export default function CustomRadio({
  name,
  id,
  checkedValue,
  handleChange,
  label,
  value,
}) {
  return (
    <label
      className={`custom-radio md:min-h-[72px] md:flex md:items-center w-full cursor-pointer select-none ${
        checkedValue ? "checked" : ""
      }`}
    >
      <input
        type="radio"
        name={name}
        id={id}
        className="sr-only"
        checked={checkedValue}
        onChange={handleChange}
        value={value}
      />
      <div className="hover:text-white hover:bg-gray-300 !py-2 !px-4 rounded transition-all duration-300  flex items-center">
        {label}
      </div>
    </label>
  );
}
